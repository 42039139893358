<template>

    <div>
        <legal-entity v-for="le in legalEntities" :key="le.id" :legalEntity="le" />
    </div>

</template>

<script>
import LegalEntity from './legalEntity'
export default {
    components: {
        'legal-entity': LegalEntity
    },

    computed: {
        legalEntities () {
            return this.$store.getters.legalEntities || []
        }
    }
}
</script>