<template>

        <base-material-card>

            <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <b>{{legalEntity.name}}</b>
            </div>

            <div class="subtitle-1 font-weight-light">
              {{legalEntity.vatNumber}}
            </div>
          </template>

    <div>

    <v-container>

            <div v-if="brands && brands.length">
                <h2>Bonsai QR</h2>
            
                    <!-- show brand, for qr payments -->
                    <v-row v-for="(brand, index) in brands" :key="brand.id">
                        <v-col cols="12">
                            <brand
                                    :brand="brand"
                                    @brandUpdated="reloadBrands" 
                            ></brand>
                            
                        </v-col>
                        <v-col cols="12">
                        <hr v-if="index != brands.length -1" />
                        </v-col>
                    </v-row>
            </div>
    </v-container>
</div>

  
    </base-material-card>

</template>

<script>
import brand from './brand'
export default {
    components: {
        brand
    },
    props: {
        legalEntity: Object
    },

    computed: {
        brands() { return this.$store.getters.brandsOfLE(this.legalEntity.id) || [] }
    },

    methods: {
        reloadBrands() { 
            this.$store.dispatch('loadBrands')
        },
        createBrand() {
            // TODO create brand, attached possiblty to LE etc..
        }
    }
}
</script>