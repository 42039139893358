<template>

    <v-container>

       
            <editable-image size="144" @change="brandModel.newLogo = $event" :src='brandModel.logo' />

                <v-text-field  v-model="brandModel.name" :label="$t('labels.name')" />

<v-dialog
        v-model="timeMenu"
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="locationModel.cutoffHour"
            :label="$t('labels.endOfDay')"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
            v-if="timeMenu"
          :value="locationModel.cutoffHour"
          full-width
          format="24hr"
          @click:hour="closePicker"
        >
        </v-time-picker>
      </v-dialog>

                
                <v-switch :label="$t('labels.referenceVisible')" v-model="brandModel.referenceVisible" />

                 
            

        

        <v-subheader>{{$t('labels.receivingAccount')}}</v-subheader>

        <v-text-field v-model="brandModel.iban" :label="$t('labels.iban')" />

        
        <v-subheader>{{$t('labels.tipping')}}</v-subheader>

        <v-switch :label="$t('labels.receiveTips')" v-model="brandModel.tippingActivated" />

        <!--
        <v-switch v-if="brandModel.tippingActivated" :label="$t('labels.greenTipping')" v-model="brandModel.greenTipping" />
        -->
        
        <v-text-field v-if="brandModel.tippingActivated" :label="$t('labels.tippingIban')" v-model="brandModel.tippingIban" />
        


         <!--
        <v-subheader>{{$t('labels.storelocator')}}</v-subheader>
        <div v-if="!brandModel.showInStoreLocator">

            Not yet in our storelocator! Ask us to put you in!
            <v-text-field @input="debouncedGeoCode" v-model="locationModel.street" :label="$t('labels.location.street')" />
            <v-text-field @input="debouncedGeoCode" v-model="locationModel.number" :label="$t('labels.location.housenumber')" />
            <v-text-field @input="debouncedGeoCode" v-model="locationModel.postalCode" :label="$t('labels.location.postalCode')" />
            <v-text-field @input="debouncedGeoCode" v-model="locationModel.city" :label="$t('labels.location.city')" />

            <v-text-field v-model="lat" :label="$t('labels.location.latitude')" />
            <v-text-field v-model="lon" :label="$t('labels.location.longitude')" />
            
        </div>
        -->

        <v-btn :disabled="loading" :loading="loading" color="primary" @click="save">{{$t('labels.confirm')}}</v-btn>


    </v-container>

</template>

<script>
import editableImage from '@/components/editableImage'
import _ from 'lodash'
export default {

    components:{
        'editable-image': editableImage
    },

    props: {
        brand: Object
    },

    data () {
        const brandClone = JSON.parse(JSON.stringify(this.brand))
        let locationModel = brandClone.locations[0]

        return {
            timeMenu: false,
            brandModel: brandClone,
            locationModel,
            //debouncedGeoCode: _.debounce(this.geoCode, 250),
            lat: '',
            lon: '',
            loading: false
        }
    },

    mounted() {
        //this.geoCode()
    },

    methods: {
        closePicker: function(v){
            v = v < 10 ? '0'+v : v;
            this.locationModel.cutoffHour = v+":00"+":00";
            this.timeMenu = false
        },
        async save() {

            try {

                if (this.brandModel.tippingActivated && !this.brandModel.tippingIban) {
                    this.$showError(this.$t('labels.tippingIbanRequired'))
                    return;
                }

                this.loading = true
                let brandUpdated = false

                if (this.brandModel.iban != this.brand.iban) {
                    await this.$api.updateAccount(this.brand.legalEntityId, this.brand.id, this.brandModel.iban)
                    brandUpdated = true
                }
                
                if (this.locationModel.cutoffHour != this.brand.locations[0].cutoffHour) {
                    await this.$api.updateLocation(this.locationModel.id, this.brand.legalEntityId, this.brand.id, this.locationModel.cutoffHour)
                    brandUpdated = true
                }

                const apiPayload = {}
                if (this.brandModel.newLogo) 
                    apiPayload.newLogo = this.brandModel.newLogo
                if (this.brandModel.name != this.brand.name) 
                    apiPayload.name = this.brandModel.name
                if (this.brandModel.referenceVisible != this.brand.referenceVisible) 
                    apiPayload.referenceVisible = this.brandModel.referenceVisible
                if (this.brandModel.tippingIban != this.brand.tippingIban) 
                    apiPayload.tippingIban = this.brandModel.tippingIban
                if (this.brandModel.greenTipping != this.brand.greenTipping) 
                    apiPayload.greenTipping = this.brandModel.greenTipping
                if (this.brandModel.tippingActivated != this.brand.tippingActivated) 
                    apiPayload.tippingActivated = this.brandModel.tippingActivated

                if (!_.isEmpty(apiPayload)) {
                    await this.$api.updateBrand(this.brand.legalEntityId, this.brand.id, apiPayload)
                    brandUpdated = true
                }
                
                this.$emit('ok', brandUpdated)

            } catch (err) {
                console.log(err)
            }

            this.loading = false
        },
        geoCode() {
            this.$http.get(`https://nominatim.openstreetmap.org/search?format=json&street=${this.locationModel.number}%20${this.locationModel.street}&city=${this.locationModel.city}&country=belgium&postalcode=${this.locationModel.postalCode}`).then(({data}) => {
                this.lat = data[0].lat
                this.lon = data[0].lon
            })
        }
    }
}
</script>