<template>
    <div>
        <v-layout class="ma-0" justify-space-between align-center>
            <v-layout class="ma-0" align-center>
                <editable-image readonly :src="brand.logo"/>
                <h2 class="ml-3">{{brand.name}}</h2>
            </v-layout>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >
                    {{$t('labels.actions')}}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item link @click="editBrand" >
                        <v-list-item-title>{{$t('labels.edit')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="generateStaticQR">
                        <v-list-item-title>{{$t('labels.downloadStaticQR')}}</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
        </v-layout>

        <v-layout row class="mt-5 ml-0">
            <v-flex>
                <div class="text-h4 text--secondary font-weight-bold">{{$t('labels.receivingAccount')}}</div>
                {{brand.iban}}
            </v-flex>



            <v-flex>
                <div class="text-h4 text--secondary font-weight-bold">{{$t('labels.endOfDay')}}
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on"
                            >
                            mdi-help-circle-outline
                            </v-icon>
                        </template>
                    <span>{{$t('labels.endOfDayTooltip')}}</span>
                    </v-tooltip>

                </div>
                {{brand.locations[0].cutoffHour}}
                <div class="mt-3 text-h4 text--secondary font-weight-bold">{{$t('labels.referenceVisible')}}
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on"
                            >
                            mdi-help-circle-outline
                            </v-icon>
                        </template>
                        <span>{{$t('labels.referenceVisibleTooltip')}}</span>
                    </v-tooltip>

                </div>
                <v-switch
                    :ripple="false"
                    readonly
                    class="mt-0"
                    v-model="brand.referenceVisible"
                    ></v-switch>
                    
                </v-flex>

                
                <v-flex>

                    <div class="mt-3 text-h4 text--secondary font-weight-bold">{{$t('labels.receiveTips')}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>{{$t('labels.tippingTooltip')}}</span>
                        </v-tooltip>
                    </div>

                    <v-switch :ripple="false" readonly class="mt-0" v-model="brand.tippingActivated" />

                    <div v-if="brand.tippingActivated">
                        <div class="text--secondary">{{$t('labels.tippingIban')}}</div>
                        {{brand.tippingIban}}
                    </div>


                    <!--
                    <div v-if="brand.tippingActivated" class="mt-3 text-h4 text--secondary font-weight-bold">{{$t('labels.greenTipping')}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>{{$t('labels.greenTippingTooltip')}}</span>
                        </v-tooltip>
                    </div>
                    <v-switch :ripple="false" readonly v-if="brand.tippingActivated" class="mt-0" v-model="brand.greenTipping" />
                    -->


                </v-flex>

                

                <!--

            <v-flex>
                <div class="text-h4 text--secondary font-weight-bold">{{$t('labels.storeLocator')}}

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on"
                            >
                            mdi-help-circle-outline
                            </v-icon>
                        </template>
                        <span>{{$t('labels.storeLocatorTooltip')}}</span>
                    </v-tooltip>

                    <v-switch
                    readonly
                    class="mt-0"
                    v-model="brand.showInStoreLocator"
                    ></v-switch>
               
                    

                </div>
            </v-flex>
            -->

        </v-layout>
        

    </div>
</template>

<script>
import editableImage from '@/components/editableImage'
import editBrandForm from './editBrandForm'
export default {
    components: {
        'editable-image': editableImage
    },

    props: {
        brand: Object
    },

    methods: {
        editBrand() {
            this.$openModal(editBrandForm, { brand: this.brand }, this)
                .then((shouldReload) => {
                    if (shouldReload) this.$emit("brandUpdated")
                })
        },
        async generateStaticQR() {
            const data = await this.$api.generateStaticQR(this.brand.id)
            var a = document.createElement("a"); //Create <a>
           console.log(data)
            a.href = "data:image/png;base64," + data.image; //Image Base64 Goes here
            a.download = this.brand.name + ".png"; //File name Here
            a.click(); //Downloaded file
        }
    }
}
</script>